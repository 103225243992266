import imageCompression from "browser-image-compression";
import constants from "../../../asset/constants";

export const utilCompressImage = async (file: File) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    fileType: "image/jpeg",
  };

  try {
    return await imageCompression(file, options);
  } catch (error) {
    console.error("이미지 압축 중 오류 발생:", error);
    return file;
  }
};

export const utilOptimizeImageWithBackend = async (file: File): Promise<File> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(`${constants.apiUrl}/api/resource/resize`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`서버 오류: ${response.status}`);
  }

  const blob = await response.blob();
  return new File([blob], file.name, { type: blob.type });
};

export const utilProcessImage = async (file: File): Promise<File> => {
  const FOUR_MB = 4 * 1024 * 1024;
  const SIXTEEN_MB = 16 * 1024 * 1024;

  if (file.size > SIXTEEN_MB) {
    console.log("16MB 이상 이미지 백엔드 최적화 처리");
    return await utilOptimizeImageWithBackend(file);
  } else if (file.size > FOUR_MB) {
    console.log("4MB 초과 이미지 클라이언트 최적화 처리");
    return await utilCompressImage(file);
  }

  return file;
};

export const utilResizeImageFile = async (file: File): Promise<File> => {
  return await utilProcessImage(file);
};

export const utilResizeImageFiles = async (files: FileList): Promise<File[]> => {
  const promises = Array.from(files).map((file) => utilResizeImageFile(file));
  return await Promise.all(promises);
};
