import { ArrowDropDown, ArrowDropUp, LocalActivity } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { React, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import find_picture from "../../asset/Img/find_picture.png";
import gridview from "../../asset/Img/gridview.png";
import imageview from "../../asset/Img/imageview.png";
import listview from "../../asset/Img/listview.png";
import apiFn from "../../asset/apiClass";
import constants, { ACADEMY_NAMES } from "../../asset/constants";
import { PaymentContext } from "../../asset/context";
import store from "../../asset/store";
import style from "../../asset/style";
import Basic from "../../common/frame/Basic";
import UiBook from "../../uiComponents/UiBook/UiBook";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import { UiInput_title } from "../../uiComponents/UiInput/UiInput";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiPeople from "../../uiComponents/UiPeople/UiPeople";
import UiSilde from "../../uiComponents/UiSilde/UiSilde";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiQna from "../QNA/UiQna";
import UiQna_grid from "../QNA/UiQna_grid";
import UiQna_image from "../QNA/UiQna_image";
import "./Search_result.css";
import Search_result_style from "./Search_result_style";
import { v4 as uuidv4 } from "uuid";

import { useMutation } from "@apollo/client";
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import API from "../../api";
import util from "../../asset/util.js";
import { AskImageQuestionDocument } from "../../types/graphql/graphql";
import styles from "./postSearch.module.scss";
import { useDeleteQuestion } from "../../api/question";

const Search = ({
  isSR_detail,
  setIsSR_detail,
  solutionList,
  setSolutionList,
  scrollKey,
  setScrolllKey,
  userInfo,
  fileObj,
  qnaData,
  setQnaData,
  setFileobj,
  hiddenUrl,
  setHiddenUrl,
  addSearch,
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const [askQuestionArgs, setAskQuestionArgs] = useState();
  const {
    requestPickTicket,
    pickResult,
    setPickResult,
    setForceUpdate,
    academyId,
    setPaymentModalOpen,
    setSelectedLectureId,
    setSelectedTeacherId,
    setAcademyId,
    paymentOpen,
    defaultAcademyId,
  } = useContext(PaymentContext);
  const [onCompleteQnaData, setOnCompleteQnaData] = useState();
  let sort_count = [
    { name: "5", value: "5" },
    { name: "10", value: "10" },
    { name: "20", value: "20" },
    { name: "50", value: "50" },
  ];
  let sort_qna = [
    { name: "모범 문항", value: "mobum" },
    { name: "완료 문항", value: "qna" },
    { name: "질문 문항", value: "live" },
    { name: "전체 문항", value: "all" },
  ];

  var [isSort, setIsSort] = useState(false);
  var [isSort_qna, setIsSort_qna] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isTeacher, setIsTeacher] = useState(true);

  // const [solutionList,setSolutionList] = useState([])

  var [academyData, setAcademyData] = useState([]);
  var [friendsList, setFriendsList] = useState([]);

  var [bookList, setBookList] = useState([1, 2, 3]);

  var [isLoading_qna, setIsLoading_qna] = useState(false);
  var [isMy, setIsMy] = useState(false);
  var [sorting, setSorting] = useState({ name: "모범 문항", value: "mobum" });

  var [listStat, setListStat] = useState("a");

  const [amount, setAmount] = useState(5);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [isImgOcr, setIsImgOcr] = useState(false);
  const imgSectionRef = useRef(null);
  const [currentSlide, setCurrentSlider] = useState(0);
  const sliderRef = useRef();
  const [askImageQuestion] = useMutation(AskImageQuestionDocument, {
    errorPolicy: "all",
  });

  const hostname = window.location.hostname;
  let channel = constants.channelId ?? "default";

  const [isSearchData, setIsSearchData] = useState(false);

  const apiPro_chk_subs = async (data) => {
    try {
      let isChk = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "bid desc",
          where: {
            "HIQDB_book_link.bid": `= '${data["bid"]}'`,
            "HIQDB_book_link.mid": `= '${data["tid"]}'`,
          },
        },
      });

      if (isChk["list"].length > 0) {
        if (isChk["list"][0]["type"] == 8 || isChk["list"][0]["type"] == 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (e) {}
  };

  // 문제 질문 등록
  const apiPro_set = async (qnadata = null) => {
    setIsLoading_qna(true);
    try {
      let isChk = await apiPro_chk_subs(qnadata);
      // console.log("qNa Set req => ",qnaData);
      qnaData.new = "y";
      qnaData["isSubs"] = isChk ? "Y" : null;
      if (params["who"] == "teacher") {
        delete qnaData["sid"];
        qnaData["tid"] = userInfo["id"];
        qnaData["mobum_date"] = "y";
        qnaData["mobum"] = "y";
        qnaData["qna_date"] = "y";
        qnaData["mobum_date"] = "y";
        qnaData["new"] = "y";
      } else {
        if (!qnaData["sid"]) {
          qnaData["sid"] = userInfo["id"];
        }
      }

      // console.log("QDATA : ", qnaData)
      // setIsLoading_qna(false)
      // return
      let questionFile;
      if (fileObj.length > 1) {
        questionFile = fileObj[0];
      } else {
        questionFile = util.dataURLtoFile(fileObj["base64"]);
      }

      const img = await apiFn.uploadFile(
        `qna/temp-${userInfo.id}-${new Date().getMilliseconds()}.jpg`,
        questionFile
      );

      let isOk = await apiFn.onlineQnaSet({
        state: {
          ...qnaData,
          ...qnadata,
          img,
        },
      });

      // console.log(isOk)
      await store.putBucket(questionFile, `qna/${isOk["qid"]}.jpg`, false, false);

      if (fileObj.length > 1) {
        for (let imgFile of Array.from(fileObj).slice(1)) {
          try {
            await apiFn.uploadFile(`qna/${isOk.qid}/${imgFile.name}`, imgFile);
            await apiFn.onlineQnaMsgSet({
              state: {
                wtype: userInfo.type,
                ctype: 90,
                qid: isOk.qid,
                content: imgFile.name,
                wid: userInfo.id,
              },
            });
          } catch (e) {
            console.error(e);
            alert(imgFile.name + "을 추가하는 도중 오류가 발생했습니다.");
          }
        }
      }

      setFileobj({});
      setIsLoading_qna(false);
      // navigate(`/talk/${params["who"]}/0/${isOk["qid"]}/${qnaData["sid"]}/${qnaData["tid"]}`)
      if (params["who"] == "teacher") {
        navigate(`/talk/${params["who"]}/0/${isOk["qid"]}/null/${qnaData["tid"]}`, {
          replace: true,
        });
        // navigate(`/message/${params["who"]}/home`, {replace: true})
      } else {
        navigate(`/talk/${params["who"]}/0/${isOk["qid"]}/${qnaData["sid"]}/${qnaData["tid"]}`, {
          replace: true,
        });
      }
    } catch (e) {
      // console.log("QNA SET Error => ",e);
      setIsLoading_qna(false);
      console.error(e);
      alert("문제 사진으로 적합하지 않은 이미지에요. 사진을 다시 한번 확인해주세요.");
      // console.log("hiddenUrl = > ",hiddenUrl);
      if (hiddenUrl?.includes("/talk")) {
        navigate(`/message/${params["who"]}/home`);
        navigate(hiddenUrl);
      } else {
        hiddenUrl
          ? navigate(hiddenUrl)
          : navigate(`/message/${params["who"]}/home`, { replace: true });
      }
      // if(e["code"] == "NO_QNA_STRING"){
      //     alert(e["msg"])

      // }
    }
  };

  const handleAskQuestionError = (msg) => {
    alert(msg);
    if (hiddenUrl?.includes("/talk")) {
      navigate(`/message/${params["who"]}/home`);
      navigate(hiddenUrl);
    } else {
      hiddenUrl
        ? navigate(hiddenUrl)
        : navigate(`/message/${params["who"]}/home`, { replace: true });
    }
  };

  const teacherAskQuestion = useCallback(async (qnadata = null) => {
    setIsLoading_qna(true);
  }, []);

  const studentAskQuestion = async ({ bid, sid, tid, channel, source, ticketType }, fileObj) => {
    setIsLoading_qna(true);
    try {
      let bucketImgPaths = [];
      // 1. 모든 이미지를 버킷에 한 번에 등록한다.
      try {
        if (fileObj?.length > 1) {
          bucketImgPaths = await Promise.all(
            Array.from(fileObj).map((imgFile) =>
              apiFn.uploadFile(`qna/temp-${sid}/${imgFile.name}`, imgFile)
            )
          );
        } else {
          let questionFile = fileObj.imageFile
            ? fileObj.imageFile
            : util.dataURLtoFile(fileObj["base64"]);
          let imgPath = `qna/temp-${sid}/-${new Date().getMilliseconds()}.jpg`;
          imgPath = await apiFn.uploadFile(imgPath, questionFile);
          bucketImgPaths.push(imgPath);
        }
      } catch (e) {
        console.error(e);
        throw new Error("이미지를 추가하는 도중 오류가 발생했습니다.");
      }
      // 2. askImageQuestion[GraphQL]를 호출한다.
      let variables;
      if (paymentOpen !== "none") {
        variables = {
          input: {
            bookId: bid,
            teacherId: tid,
            studentId: sid,
            images: bucketImgPaths,
            channel,
            source,
            academyId,
            ticketType,
          },
        };
      } else {
        variables = {
          input: {
            bookId: bid,
            teacherId: tid,
            studentId: sid,
            images: bucketImgPaths,
            channel,
            source,
            academyId,
          },
        };
      }

      let { errors, data } = await askImageQuestion({ variables });
      if (!data?.askImageQuestion) {
        throw new Error(errors?.[0]?.message);
      }
      setFileobj({});
      setIsLoading_qna(false);
      const qid = data.askImageQuestion.id;
      cleanUpPayment();
      navigate(`/talk/student/0/${qid}/${sid}/${tid}`, { replace: true });
    } catch (e) {
      setIsLoading_qna(false);
      console.error(e);
      cleanUpPayment();
      handleAskQuestionError(
        e?.message || "등록하는 도중 오류가 발생했습니다. 사진을 다시 한번 확인해주세요."
      );
    }
  };

  const cleanUpPayment = () => {
    setPickResult(false);
    setPaymentModalOpen(false);
    setSelectedLectureId(null);
    setSelectedTeacherId("");
    setAcademyId(defaultAcademyId);
  };

  const startPickTicketType = useCallback(
    (args, onCompleteQnaData) => {
      if (onCompleteQnaData) {
        setOnCompleteQnaData(onCompleteQnaData);
      }
      setAskQuestionArgs(args);
      requestPickTicket();
    },
    [requestPickTicket, setAskQuestionArgs]
  );

  const onPickTicketType = useCallback(async () => {
    await studentAskQuestion({ ...askQuestionArgs, ticketType: pickResult }, fileObj);
    if (onCompleteQnaData) {
      setQnaData((prev) => ({ ...prev, ...onCompleteQnaData }));
    }
    setOnCompleteQnaData(null);
    setAskQuestionArgs(null);
    setPickResult(false);
    setPaymentModalOpen(false);
    setForceUpdate(new Date().getTime());
  }, [pickResult, askQuestionArgs, fileObj, setQnaData, onCompleteQnaData, setForceUpdate]);

  const apiPro_del = async (data) => {
    try {
      console.log("del data : ", data);
      await useDeleteQuestion(data.id);
      await apiPro_read();
    } catch (e) {
      console.log(e);
    }
  };

  // 비슷한 문제 풀이 리스트
  const apiPro_read = async (sort, amount = 5, status = "all", my) => {
    try {
      setIsSearchData(true);
      if (["b", "t"].includes(params.type)) return;
      console.log("addSearch in Search", addSearch);
      // console.log("@@ ## # $ $ $ $")
      // let data = await apiFn.onlineQnaQuery({
      // text : keyword
      let state = {
        isExcept: "Y",
      };
      // console.log("fileObj ? ",fileObj);
      if (fileObj) {
        if (fileObj["isText"]) {
          state["text"] = fileObj["text"];
        } else {
          state["img"] = fileObj["base64"]
            ?.replace(/^data:image\/png;base64,/, "")
            ?.replace(/^data:image\/jpeg;base64,/, "");
        }
      }

      // 학생 : 선생님 (1:1) 질문보기
      if (params["form"].includes("see")) {
        state["mid"] = params["form"].split("&")[1];
        delete state["isExcept"];
        delete state["img"];
      }
      state["status"] = "mobum";

      if (status === "all") {
        state["status"] = "";
      } else {
        state["status"] = status;
      }

      if (my) {
        state["isMy"] = "Y";
      } else if (my) {
        state["isMy"] = "Y";
      }
      if (params.who === "teacher") {
        state.book_type = currentSlide;
      }

      console.log("QNALIST state : ", state, addSearch);
      let data = await apiFn.qnaList({
        state: {
          ...state,
          ...addSearch,
          id: userInfo["id"],
          isHome: "Y",
          page: 1,
          amount: params["form"].includes("see") ? 999 : amount,
          sort: sort ? sort : "reg_date desc",

          // (
          // params["form"].includes("see") ?
          // :
          // ( sort ? sort : "reg_date desc" )
          // )
          // fileObj ?
          // (
          //     params["form"].includes("see") ?
          //     "reg_date desc"
          //     :
          //     ( sort ? sort : "score desc" )
          // )
          // :
          // "reg_date desc"
          // img 처리일 때는 무시됨.
        },
      });

      // console.log("Search Solution List => ",data["list"])

      // data["list"].map((v)=>{
      // console.log("score : ",v['score'])
      // console.log("tid : ",v["tid"])
      // })

      setSolutionList(data["list"]);
      setIsImgOcr(true);

      // // console.log("Search Solution List => ",data)
    } catch (e) {
      console.error(e);
      alert("이미지를 다시 확인해 주세요.");
      if (hiddenUrl) {
        navigate(hiddenUrl);
      } else {
        navigate(`/message/${params.who}/home`);
      }
      setFileobj({});
    } finally {
      setIsLoading(false);
      setIsSearchData(false);
    }
  };

  // 교재 리스트
  const apiPro_read_book = async () => {
    try {
      if (params.who === "teacher") {
        const isOk = await apiFn.comApi({
          state: {
            table: "book",
            page: 1,
            amount: 999,
            u_id: userInfo["id"],
            sort: "reg_date desc",
            ref: [
              "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
              "HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id",
            ],
            where: {
              "HIQDB_book_link.mid": `= '${userInfo["id"]}'`,
              "HIQDB_book.status": `!= '9'`,
              // "HIQDB_book_link.fid" : `= 0`,
              "HIQDB_book_link.type": `!= '8'`,
              // "HIQDB_book_link.cid" : `= 0`,
            },
          },
        });
        const bookArray = isOk.list.filter(
          (book, index, callback) =>
            index === callback.findIndex((b) => b.id === book.id) && book.book_type === 0
        );
        setBookList(bookArray);
      } else {
        if (!qnaData.tid || Object.keys(fileObj) <= 0) {
          navigate(`/message/${params.who}/home`);
          return;
        }
        const userClassList = await API.getUserClassList(
          userInfo.id,
          `classType=1&isClassroomRequired=true`
        );
        const teacherClassList = await API.getUserClassList(
          qnaData.tid,
          `classType=1&isClassroomRequired=true`
        );
        const commonClassList = userClassList.filter(
          (studentClassData) =>
            teacherClassList.findIndex(
              (teacherClassData) =>
                (teacherClassData.cid === studentClassData.cid) & (teacherClassData.status === 1)
            ) > -1
        );
        if (commonClassList.length === 0) {
          alert(
            `${ACADEMY_NAMES[constants.classroomId] ?? "HIQSUM"}에서 선택 할 수 있는 수업이 없어요.`
          );
          navigate(-2);
          setFileobj({});
          return;
        }
        if (commonClassList.length === 1) {
          await selectClassWithDefaultBook(commonClassList[0].cid);
          return;
        }
        const classListReArrange = commonClassList.reduce((acc, classData) => {
          if (classData.status === 0) return acc;
          const classDataReArrange = {
            academyName: classData.classroom_name,
            pid: classData.pid,
            name: classData.class_name,
            id: classData.cid,
            cid: classData.cid,
          };
          acc.push(classDataReArrange);
          return acc;
        }, []);
        setAcademyData(classListReArrange);
      }

      // console.log("book List ",isOk["list"])
    } catch (e) {
      console.error(e);
      alert("수업 혹은 교재를 불러오는 도중 오류가 발생했습니다.");
      navigate(-3);
    }
  };

  const selectClassWithDefaultBook = async (cid) => {
    let book = null;
    try {
      setIsLoading_qna(true);
      const response = await fetch(`${constants.apiUrl}/class/${cid}/default`);

      if (response.status !== 200) {
        throw new Error("no default");
      }

      book = await response.json();

      // await apiPro_set({...qnaData, bid: book.bid})
    } catch (e) {
      alert("수업 기본 교재가 없습니다. 선생님께 문의해주세요.");
      throw new Error("Default Not Found");
    } finally {
      setIsLoading_qna(false);
    }

    if (!book) {
      throw new Error("no book");
    }
    try {
      if (params["who"] == "teacher") {
        await apiPro_set({ ...qnaData, bid: book.bid });
        setQnaData((prev) => ({ ...prev, bid: book.bid }));
      } else {
        startPickTicketType(
          {
            bid: parseInt(book.bid),
            tid: qnaData["tid"],
            sid: userInfo["id"],
            channel: channel,
            academyId,
          },
          { bid: book.id }
        );
      }
    } catch (e) {
      console.error(e);
      alert("문제 등록 중 오류가 발생했습니다");
    }
  };

  // 내 클래스룸 목록
  const apiPro_read_class = async (d) => {
    try {
      // // console.log("DD => ",d);
      let where_obj_ = {};
      if (d) {
        where_obj_["HIQDB_class.pid"] = `= '${d["cid"]}'`;
        where_obj_["HIQDB_member_class.mid"] = `='${userInfo["id"]}'`;
        where_obj_["HIQDB_class.status"] = `!='9'`;
      } else {
        where_obj_["HIQDB_member_class.mid"] = `='${userInfo["id"]}'`;
        where_obj_["HIQDB_class.type"] = `='${params["who"] == "teacher" ? "0" : "1"}'`;
        where_obj_["HIQDB_class.status"] = `!='9'`;
      }

      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            "HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.pid|HIQDB_class.id=HIQDB_member_class.cid",
          ],
          where: where_obj_,
        },
      });

      let array = [];
      let s_array = [];
      if (params["who"] === "teacher") {
        data["list"].map((v) => {
          // // console.log(v)
          if (v.status !== 2) {
            array.push(v);
          }
        });
        setAcademyData([...array]);
      } else {
        let reduceArray = data["list"].reduce((a, v, i) => {
          return [...a, v["pid"]];
        }, []);

        // // console.log("reduceArray => ",reduceArray);
        let where_obj = {};
        if (d) {
          where_obj["HIQDB_class.pid"] = `= '${d["id"]}'`;
        } else {
          where_obj["HIQDB_class.id"] = `in (${reduceArray})`;
        }
        let data2 = await apiFn.comApi({
          state: {
            table: "class",
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            where: where_obj,
          },
        });

        setAcademyData([...data2["list"]]);
        // // console.log("data2 => ",data2["list"])
      }

      // // console.log("data => ",data["list"])
      await apiPro_read_teacher(d);
    } catch (e) {
      // console.log(e)
    }
  };

  //
  const apiPro_read_teacher = async (d) => {
    try {
      let state = {
        page: 1,
        amount: 9999,
        sort: "reg_date desc",
      };
      if (params["who"] == "teacher") {
        state["tid"] = userInfo["id"];
      } else {
        state["sid"] = userInfo["id"];
        if (qnaData["bid"]) {
          state["bid"] = qnaData["bid"];
        }
      }
      if (d) {
        state["cid"] = d["id"];
      }
      let data = await apiFn.friendsList({
        state: state,
      });
      setFriendsList([...data["list"]]);
    } catch (e) {
      // console.log("@@ ",e)
    }
  };

  useEffect(() => {
    // // console.log("parmas => ",params)
    if (params.who === "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }

    if (params.type === "t") {
      apiPro_read_class();
    }
    if (params.type === "b") {
      apiPro_read_book();
    }
  }, [params.who, params.type, isImgOcr]);

  useEffect(() => {
    try {
      // console.log("@@@@#@#@ " ,window.localStorage.getItem("s_result_sort"))
      // setIsLoading(true)
      if (!fileObj["base64"] && !fileObj["text"] && fileObj.length === 0) {
        // alert("올바른 접근 형식이 아닙니다.")
        navigate(`/message/${params["who"]}/home`);
      } else {
        if (isSR_detail) {
          setIsLoading(false);
          setIsSR_detail(false);
        } else {
          setIsLoading(true);
          apiPro_read(undefined, amount, sorting?.value, isMy);
        }
        if (params["type"] === "t") {
          delete qnaData["tid"];
        }
        if (params["type"] === "b") {
          delete qnaData["bid"];
        }
      }
      // if(window.localStorage.getItem("search_result_my")){
      //     setIsMy(JSON.parse(window.localStorage.getItem("search_result_my")))
      // }
      // if(window.localStorage.getItem("search_result_list")){
      //     setListStat(window.localStorage.getItem("search_result_list"))
      // }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  }, [amount, sorting, addSearch, isMy]);

  useEffect(() => {
    // console.log("qnaData => ",qnaData);
    if (!fileObj["base64"]) return;
    if (isLoading) {
      if (qnaData["tid"] || qnaData["bid"]) {
        console.log("!!@@@");
        setQnaData({
          ...qnaData,
          new: "y",
          qna_date: "y",
          sid: userInfo["id"],
          img: fileObj["base64"]
            .replace(/^data:image\/png;base64,/, "")
            .replace(/^data:image\/jpeg;base64,/, ""),
        });
        setIsLoading(false);
        // if(qnaData["tid"]){
        //     navigate(`/searchresult/student/${params["who"]}/b`)
        // }
        // else {
        //     navigate(`/searchresult/student/${params["who"]}/t`)
        // }
      }
    }
  }, [qnaData, isLoading]);

  useEffect(() => {
    if (window.localStorage.getItem("search_result_my")) {
      setIsMy(JSON.parse(window.localStorage.getItem("search_result_my")));
    }
    if (window.localStorage.getItem("search_result_list")) {
      setListStat(window.localStorage.getItem("search_result_list"));
    }
    if (window.localStorage.getItem("s_result_sort")) {
      setSorting(JSON.parse(window.localStorage.getItem("s_result_sort")));
    }
    if (window.localStorage.getItem("s_result_amount")) {
      setAmount(Number(window.localStorage.getItem("s_result_amount")));
    }
  }, []);

  useEffect(() => {
    apiPro_read(undefined, undefined, sorting.value, isMy);
  }, [currentSlide]);

  // 티켓 종류가 선택되었을 때
  useEffect(async () => {
    if (pickResult && askQuestionArgs) {
      await onPickTicketType();
    }
  }, [pickResult, askQuestionArgs]);

  const titleContent = useMemo(() => {
    let text = "문제검색결과";
    if (currentSlide === 1) text = "PDF 검색결과";
    else if (currentSlide === 2) text = "내 필기 검색결과";
    else if (currentSlide === 3) text = "시중 교재 검색 결과";

    return text;
  }, [currentSlide]);
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "50px 0px",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "75vw",
              }}
              src={find_picture}
            />
            <CircularProgress />
          </div>
          <UiTextBtn
            btnStyle={{
              color: style.common.fontColor_3,
              fontSize: "14px",
              padding: "20px 0px",
            }}
            text={"검색을 취소하기"}
            fnClick={() => {
              setIsLoading(false);
              navigate(`/message/${params["who"]}/home`);
            }}
          />
        </div>
      ) : params["type"] == "de" ? (
        <Basic
          isPadding={false}
          userInfo={userInfo}
          title={titleContent}
          route={-1}
          backEvent={() => {
            window.localStorage.removeItem("saveSearchPrevUrl");
            setFileobj({});
          }}
          containerStyle={{ overflow: "hidden", maxHeight: "100vh" }}
          pastSearchQna={true}
        >
          <div
            style={{
              paddingBottom: "60px",
              height: "100%",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                width: "100%",
                maxHeight: "30%",
                overflowY: "auto",
              }}
              ref={imgSectionRef}
            >
              {params["form"] == "text" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px 20px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {fileObj["text"]}
                </div>
              ) : (
                params["form"] == "de" && (
                  <div
                    className="solCrop__drag-handle"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "80vw",
                      height: "calc(100% - 43px)",
                      margin: "0 auto",
                      position: "relative",
                    }}
                  >
                    <img
                      src={fileObj["base64"]}
                      style={{
                        width: "80vw",
                        maxWidth: style.common.maxWidth,
                        border: "1px solid",
                        borderColor: style.common.borderColor4,
                        objectFit: "contain",
                        objectPosition: "center",
                        borderRadius: "5px",
                      }}
                    />
                    <div
                      style={{
                        ...Search_result_style.Frame_c,
                        top: "0",
                        left: 0,
                        borderLeft: Search_result_style.border_,
                        borderTop: Search_result_style.border_,
                      }}
                    ></div>
                    <div
                      style={{
                        ...Search_result_style.Frame_c,
                        top: "0",
                        right: 0,
                        borderRight: Search_result_style.border_,
                        borderTop: Search_result_style.border_,
                      }}
                    ></div>
                    <div
                      style={{
                        ...Search_result_style.Frame_c,
                        bottom: "0",
                        left: 0,
                        borderLeft: Search_result_style.border_,
                        borderBottom: Search_result_style.border_,
                      }}
                    ></div>
                    <div
                      style={{
                        ...Search_result_style.Frame_c,
                        bottom: "0",
                        right: 0,
                        borderRight: Search_result_style.border_,
                        borderBottom: Search_result_style.border_,
                      }}
                    ></div>
                  </div>
                )
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {!params["form"].includes("see") && (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          fontSize: "14px",
                          fontWeight: 400,
                          alignItems: "center",
                        }}
                      >
                        {/* {qnaData.length > 0 && `총 ${qnaData[0]["tcnt"]} 문항`} */}
                        {`${amount ? amount : "5"}개씩`}
                        {isSort ? (
                          <ArrowDropUp
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort(false);
                            }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort(true);
                            }}
                          />
                        )}
                      </div>
                      {isSort ? (
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            // top:10,
                            borderRadius: "20px",
                            border: "1px solid #F6F6F6",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            padding: "3px 0px",
                            backgroundColor: "white",
                            zIndex: 10,
                          }}
                        >
                          {sort_count.map((v, i) => (
                            <div
                              style={{
                                padding: "15px 0px",
                                width: "120px",
                                backgroundColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#3D3A39",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => {
                                console.log(v);
                                setIsSort(false);
                                window.localStorage.setItem("s_result_amount", v["value"]);
                                setAmount(v.value);
                                apiPro_read(undefined, v.value, sorting.value, isMy);
                              }}
                            >
                              {v.value}개 씩
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        fontWeight: 400,
                        alignItems: "center",
                      }}
                    >
                      {`${sorting ? sorting.name : "모범문항"}`}
                      {isSort_qna ? (
                        <ArrowDropUp
                          style={{
                            fontSize: "40px",
                            marginBottom: "3px",
                          }}
                          onClick={() => {
                            setIsSort_qna(false);
                          }}
                        />
                      ) : (
                        <ArrowDropDown
                          style={{
                            fontSize: "40px",
                            marginBottom: "3px",
                          }}
                          onClick={() => {
                            setIsSort_qna(true);
                          }}
                        />
                      )}
                    </div>
                    {isSort_qna ? (
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          // top:10,
                          borderRadius: "20px",
                          border: "1px solid #F6F6F6",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          padding: "3px 0px",
                          backgroundColor: "white",
                          zIndex: 10,
                        }}
                      >
                        {sort_qna.map((v, i) => (
                          <div
                            style={{
                              padding: "15px 0px",
                              width: "120px",
                              backgroundColor: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#3D3A39",
                              borderRadius: "20px",
                            }}
                            onClick={(e) => {
                              // apiPro_read("", "", v["name"])
                              apiPro_read(undefined, amount, v.value, isMy);
                              window.localStorage.setItem("s_result_sort", JSON.stringify(v));
                              setSorting(v);
                              setIsSort_qna(false);
                            }}
                          >
                            {v["name"]}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {listStat == "listview" ? (
                    <img
                      src={listview}
                      style={{
                        width: "22px",
                        height: "22px",
                      }}
                      onClick={() => {
                        setListStat("gridview");
                        window.localStorage.setItem("explain_section", "gridview");
                      }}
                    />
                  ) : listStat == "gridview" ? (
                    <img
                      src={gridview}
                      style={{
                        width: "22px",
                        height: "22px",
                      }}
                      onClick={() => {
                        setListStat("imageview");
                        window.localStorage.setItem("explain_section", "imageview");
                      }}
                    />
                  ) : (
                    <img
                      src={imageview}
                      style={{
                        width: "22px",
                        height: "22px",
                      }}
                      onClick={() => {
                        setListStat("listview");
                        window.localStorage.setItem("explain_section", "listview");
                      }}
                    />
                  )}
                </div>
                {!params["form"].includes("see") && (
                  <div
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <UiCheckBox
                      value={"isImportant"}
                      name="iI"
                      className={params["who"] === "teacher" ? "t" : "s"}
                      checked={isMy}
                      fnCheck={(e) => {
                        window.localStorage.setItem("search_result_my", JSON.stringify(!isMy));
                        apiPro_read(undefined, amount, sorting.value, !isMy);
                        setIsMy((prev) => !prev);
                      }}
                    />
                    <div
                      style={{
                        marginLeft: 3,
                      }}
                    >
                      {params.who === "student" && "내 문항"}
                      {params.who === "teacher" && ` 내 ${currentSlide === 0 ? "문항" : "PDF"}`}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginTop: "10px",
                height: `calc(100% - ${imgSectionRef?.current?.offsetHeight}px - 100px)`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px 5px 50px",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      검색결과
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        marginLeft: "3px",
                      }}
                    >
                      {`총 ${solutionList?.length} 문항`}
                    </div>
                  </div>

                  {params.who === "teacher" && params.form === "de" && (
                    <div className={styles.buttonSection}>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          sliderRef.current.slickGoTo(currentSlide - 1);
                        }}
                      >
                        <KeyboardDoubleArrowLeft />
                        {currentSlide === 0
                          ? "시중 교재 검색 결과 보기"
                          : currentSlide === 1
                            ? "문제 검색 보기"
                            : currentSlide === 2
                              ? "PDF 검색 결과 보기"
                              : "내 필기 검색 보기"}
                      </button>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          sliderRef.current.slickGoTo(currentSlide + 1);
                        }}
                      >
                        {currentSlide === 0
                          ? "PDF 검색 결과 보기"
                          : currentSlide === 1
                            ? "내 필기 검색 보기"
                            : currentSlide === 2
                              ? "시중 교재 검색 결과 보기"
                              : "문제 검색 보기"}
                        <KeyboardDoubleArrowRight />
                      </button>
                    </div>
                  )}
                </div>
                {params.who === "teacher" && params.form === "de" && (
                  <Slider
                    dots={false}
                    infinite={true}
                    slidesToScroll={1}
                    slidesToShow={1}
                    afterChange={(current) => setCurrentSlider(current)}
                    ref={sliderRef}
                    arrows={false}
                  >
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div key={index}>
                        {!isSearchData ? (
                          <>
                            {solutionList?.length === 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  width: "100%",
                                  height: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                }}
                              >
                                검색 결과가 없습니다.
                              </div>
                            )}
                            {listStat === "gridview" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                }}
                              >
                                {solutionList?.map((v, i) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexGrow: 1,
                                      maxWidth: "200px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <UiQna_grid
                                      index={i}
                                      data={v}
                                      fnClick={() => {
                                        setIsSR_detail(true);
                                        window.localStorage.setItem("isImgSearch", true);
                                        if ([0, 3].includes(currentSlide)) {
                                          navigate(
                                            "/talk/" +
                                              params["who"] +
                                              "/" +
                                              "searchresult" +
                                              "/" +
                                              v["id"] +
                                              "/" +
                                              v["sid"] +
                                              "/" +
                                              v["tid"]
                                          );
                                        } else if ([1, 2].includes(currentSlide)) {
                                          navigate(
                                            `/pdfView/${v?.bid}?questionId=${v?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              solutionList?.map((v, i) =>
                                listStat === "listview" ? (
                                  // /   / <UiExplain
                                  <UiQna
                                    index={i}
                                    isDot={v["tid"] == userInfo["id"] ? true : false}
                                    isSearch={true}
                                    isMsgSection={false}
                                    apiPro={(id) => {
                                      apiPro_read(undefined, sorting, isMy, isAnswer);
                                    }}
                                    apiPro_del={(d) => {
                                      apiPro_del(d);
                                    }}
                                    fnClick={(d) => {
                                      setIsSR_detail(true);
                                      // console.log("UiExpalin data => ",d)
                                      setHiddenUrl(-1);
                                      window.localStorage.setItem("isImgSearch", true);
                                      if ([0, 3].includes(currentSlide)) {
                                        navigate(
                                          "/talk/" +
                                            params["who"] +
                                            "/" +
                                            "searchresult" +
                                            "/" +
                                            v["id"] +
                                            "/" +
                                            v["sid"] +
                                            "/" +
                                            v["tid"]
                                        );
                                      } else if ([1, 2].includes(currentSlide)) {
                                        navigate(
                                          `/pdfView/${v?.bid}?questionId=${v?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                        );
                                      }
                                    }}
                                    data={v}
                                  />
                                ) : (
                                  <UiQna_image
                                    isDot={v["tid"] == userInfo["id"] ? true : false}
                                    index={i}
                                    data={v}
                                    isInfinite={false}
                                    fnClick={(d) => {
                                      setIsSR_detail(true);
                                      setScrolllKey(d["id"]);
                                      window.localStorage.setItem("isImgSearch", true);
                                      if ([0, 3].includes(currentSlide)) {
                                        navigate(
                                          "/talk/" +
                                            params["who"] +
                                            "/" +
                                            "searchresult" +
                                            "/" +
                                            v["id"] +
                                            "/" +
                                            v["sid"] +
                                            "/" +
                                            v["tid"]
                                        );
                                      } else if ([1, 2].includes(currentSlide)) {
                                        navigate(
                                          `/pdfView/${v?.bid}?questionId=${v?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                        );
                                      }
                                    }}
                                    apiPro={(id) => {
                                      apiPro_read(undefined, undefined, sorting.value, isMy);
                                    }}
                                    apiPro_del={(d) => {
                                      apiPro_del(d);
                                    }}
                                  />
                                )
                              )
                            )}
                          </>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <CircularProgress
                              style={{
                                color: style.common.t_color,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </Slider>
                )}
                {(params.who === "student" ||
                  (params.who === "teacher" && params.form !== "de")) && (
                  <>
                    {solutionList?.length > 0 ? (
                      listStat == "gridview" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {solutionList?.map((v, i) => (
                            <div
                              key={uuidv4()}
                              style={{
                                display: "flex",
                                flexGrow: 1,
                                maxWidth: "200px",
                                justifyContent: "center",
                              }}
                            >
                              <UiQna_grid
                                type={v.type}
                                isDot={true}
                                index={i}
                                data={v}
                                isTeacher={params["who"] == "teacher" ? true : false}
                                fnClick={() => {
                                  setIsSR_detail(true);
                                  window.localStorage.setItem("isImgSearch", true);
                                  navigate(
                                    `/talk/${params["who"]}/searchresult/${d["id"]}/${d["sid"]}/${d["tid"]}`
                                  );
                                }}
                                apiPro={(id) => {
                                  apiPro_read(undefined, undefined, sorting.value, isMy);
                                }}
                                apiPro_del={(d) => {
                                  apiPro_del(d);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        solutionList?.map((v, i) =>
                          listStat === "listview" ? (
                            // <UiExplain
                            <UiQna
                              statInfo={{ type: v.type }}
                              index={i}
                              isSearch={true}
                              isTeacher={params["who"] === "teacher"}
                              isDot={true}
                              isMsgSection={false}
                              setScrolllKey={setScrolllKey}
                              scrollKey={scrollKey}
                              data={v}
                              fnLongClick={() => {}}
                              fnClick={(d) => {
                                setIsSR_detail(true);
                                setScrolllKey(d["id"]);
                                window.localStorage.setItem("isImgSearch", true);
                                navigate(
                                  `/talk/${params["who"]}/searchresult/${d["id"]}/${d["sid"]}/${d["tid"]}`
                                );
                              }}
                              apiPro={(id) => {
                                apiPro_read(undefined, undefined, sorting.value, isMy);
                              }}
                              apiPro_del={(d) => {
                                apiPro_del(d);
                              }}
                            />
                          ) : (
                            // solutionList.map((v,i)=>
                            <UiQna_image
                              statInfo={{ type: v.type }}
                              index={i}
                              isSearch={true}
                              isDot={true}
                              data={v}
                              isTeacher={params["who"] === "teacher"}
                              fnClick={(d) => {
                                setIsSR_detail(true);
                                window.localStorage.setItem("isImgSearch", true);
                                navigate(
                                  `/talk/${params["who"]}/searchresult/${d["id"]}/${d["sid"]}/${d["tid"]}`
                                );
                                // navigate("/talk/"+params["who"]+"/"+"post"+"/"+d["id"]+"/"+d["sid"]+"/"+d["tid"])
                              }}
                              apiPro={(id) => {
                                apiPro_read(undefined, undefined, sorting.value, isMy);
                              }}
                              apiPro_del={(d) => {
                                apiPro_del(d);
                              }}
                            />
                          )
                        )
                      )
                    ) : (
                      // )

                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        검색 결과가 없습니다.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {
              // params["who"] == "student" &&
              // solutionList.length == 0 &&
              (params["form"] == "de" || params["form"] == "text") && (
                <div
                  style={{
                    display: "flex",
                    fontSize: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 0px",
                    height: "30px",
                    backgroundColor: "white",
                    zIndex: 99,
                    width: "100%",
                    position: "fixed",
                    borderTop: "1px solid",
                    borderColor: style.common.borderColor,
                    bottom: 0,
                    maxWidth: style.common.maxWidth,
                    // backgroundColor:"orange",
                  }}
                  onClick={() => {
                    // delete fileObj["isText"]
                    if (fileObj["isText"]) {
                      setQnaData({
                        ...qnaData,
                        new: "y",
                        qna_date: "y",
                        sid: userInfo["id"],
                        img: fileObj["text"],
                      });
                    } else {
                      setQnaData({
                        ...qnaData,
                        new: "y",
                        qna_date: "y",
                        sid: userInfo["id"],
                        img: fileObj["base64"]
                          .replace(/^data:image\/png;base64,/, "")
                          .replace(/^data:image\/jpeg;base64,/, ""),
                      });
                    }

                    if (params["who"] == "teacher") {
                      navigate(`/searchresult/teacher/${params["who"]}/b`);
                    } else {
                      if (qnaData["tid"]) {
                        navigate(`/searchresult/student/${params["who"]}/b`);
                      } else {
                        navigate(`/searchresult/student/${params["who"]}/t`);
                      }
                    }
                  }}
                >
                  <LocalActivity
                    style={{
                      marginRight: "5px",
                      color: "orange",
                    }}
                  />
                  {fileObj["isText"]
                    ? "검색한 텍스트로 새 질문 만들기"
                    : "검색한 사진으로 새 질문 만들기"}
                </div>
              )
            }
          </div>
        </Basic>
      ) : params["type"] == "t" ? (
        <Basic title={"새 질문 만들기"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              // padding : style.common.padding,
            }}
          >
            <div
              style={{
                // width:"100%",
                fontSize: "14px",
                // padding:"10px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: style.common.borderColor2,
                  height: "20px",
                  padding: "10px",
                }}
              >
                문제를 질문할 선생님을 선택해주세요.
              </div>
            </div>
            <UiSilde
              data={academyData}
              fnClick={() => {}}
              apiPro={(d) => {
                // // console.log(d)
                // apiPro_read_2(d)
                apiPro_read_class(d);
              }}
            />
            <UiPeople
              userInfo={userInfo}
              isTeacher={isTeacher}
              isPeople={true}
              data={friendsList}
              isOnly={true}
              fnClick={(data) => {
                // console.log(data)
                setQnaData({
                  ...qnaData,
                  tid: data["id"],
                });
                if (qnaData["bid"]) {
                  navigate("/searchresult/student/student/n");
                } else {
                  navigate("/searchresult/student/student/b");
                }
              }}
            />
          </div>
        </Basic>
      ) : params.type === "b" ? (
        <Basic title={"새 질문 만들기"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              // padding : style.common.padding,
            }}
          >
            <div
              style={{
                // width:"100%",
                fontSize: "14px",
                // padding:"10px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: style.common.borderColor2,
                  height: "20px",
                  padding: "10px",
                }}
              >
                {params.who === "teacher" ? "교재를 선택해주세요." : "질문할 수업을 선택해주세요."}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "0px 0px",
                // justifyContent:"space-between",
              }}
            >
              {params.who === "teacher" &&
                bookList?.map((v, i) => (
                  <UiBook
                    data={v}
                    isShare={false}
                    navigate={() => {}}
                    fnClick={(data) => {
                      // // console.log("data => ",data)
                      setQnaData({
                        ...qnaData,
                        bid: data["id"],
                      });
                      navigate(`/searchresult/${params["who"]}/student/n`);
                    }}
                  />
                ))}
              {params.who === "student" &&
                academyData?.map((classroom) => {
                  return (
                    <button
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        backgroundColor:
                          selectedClassId === classroom.id
                            ? style.common.s_color_msg
                            : "transparent",
                        border: "1px solid #a0a0a0",
                        borderRadius: 4,
                        marginBottom: 8,
                        height: 48,
                        fontSize: 18,
                      }}
                      onClick={() => {
                        console.log("classroom.id", classroom.id);
                        setSelectedClassId(classroom.id);
                        selectClassWithDefaultBook(classroom.id);
                        setSelectedLectureId(classroom.id);
                      }}
                    >
                      <div>
                        {classroom.name}
                        <span style={{ fontSize: 12, color: "#a0a0a0" }}>
                          &nbsp;|&nbsp;
                          {classroom.academyName}
                        </span>
                      </div>
                    </button>
                  );
                })}
            </div>
          </div>
        </Basic>
      ) : params["type"] == "n" ? (
        <Basic title={"새 질문 만들기"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              // padding : style.common.padding,
            }}
          >
            <div
              style={{
                // width:"100%",
                fontSize: "14px",
                // padding:"10px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: style.common.borderColor2,
                  minHeight: "20px",
                  padding: "10px",
                }}
              >
                {
                  "문제 번호 혹은 문항 정보를 간략 입력해주세요. (옵션) <예시> 3장 1번 문제, 나형 23번 문제"
                }
              </div>
            </div>
            <div
              style={
                {
                  // display : "flex",
                  // width:"100%",
                  // flexWrap : "wrap",
                  // padding : "0px 10px",
                  // justifyContent:"space-between",
                }
              }
            >
              <UiInput_title
                maxLength={30}
                // title="문항 입력"
                title="문항명 입력"
                fnChange={(e) => {
                  setQnaData({
                    ...qnaData,
                    title: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              paddingBottom: "20px",
              width: "100%",
              flex: 1,
            }}
          >
            <UiBtn
              btnStyle={{
                borderColor: style.common.s_color,
                backgroundColor: style.common.s_color,
                color: "white",
              }}
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <LocalActivity
                    style={{
                      color: "white",
                      marginRight: "5px",
                    }}
                  />
                  질문 등록하기
                </div>
              }
              fnClick={() => {
                if (params["who"] == "teacher") {
                  apiPro_set();
                } else {
                  // 수업 상셍화면 -> 교재선택 -> 질문하기 -> 사진선택 -> 새질문만들기 -> 선생님 선택 -> 문항명 입력
                  studentAskQuestion(
                    {
                      bid: parseInt(qnaData["bid"]),
                      tid: qnaData["tid"],
                      sid: userInfo["id"],
                      source: qnaData["title"],
                      channel: channel,
                      academyId,
                    },
                    fileObj
                  );
                }
              }}
            />
          </div>
        </Basic>
      ) : (
        <></>
      )}
      {isLoading_qna && <UiLoading text="질문을 등록 중입니다." />}
    </>
  );
};

export default Search;
