import React, { ReactElement, useContext, useState } from "react";
import { useRecoilState } from "recoil";
import { questionTypeModalAtom } from "../../../../application/recoils/modal/atoms";
import { CircularProgress, Modal } from "@mui/material";
import { PaymentContext } from "../../../../asset/context";
import { getUser } from "../../../../asset/storage";
import { useMutation } from "@apollo/client";
import { AskImageQuestionDocument, FcmChannelType } from "../../../../types/graphql/graphql";
import { CreateQuestionService } from "../../../../shared/service/createQuestion.service";
import constants from "asset/constants";
import { useNavigate } from "react-router";

export default function QuestionTypeModal(): ReactElement {
  const [modalData, setModalData] = useRecoilState(questionTypeModalAtom);
  const [isLoading, setIsLoading] = useState(false);
  const user = getUser();
  const { selectedLectureId, selectedTeacherId, academyId, cleanUpPayment } =
    useContext(PaymentContext);
  const navigate = useNavigate();
  let channel = constants.channelId ?? "default";
  const [askImageQuestion] = useMutation(AskImageQuestionDocument, {
    errorPolicy: "all",
  });

  const handleQuestionDataWithDefaultBookUploadImages = async () => {
    setIsLoading(true);
    const questionData = {
      lectureId: selectedLectureId,
      teacherId: selectedTeacherId,
      academyId: academyId,
      studentId: user.id,
      images: modalData.images,
    };
    return await CreateQuestionService.createQuestionData({ questionData });
  };

  const resetModalData = () => {
    alert("질문이 등록되었습니다.");
    setIsLoading(false);
    setModalData({
      isOpen: false,
      images: [],
    });
    cleanUpPayment();
    navigate(`/message/${user.type === 1 ? "student" : "teacher"}/home`, { replace: true });
  };

  return (
    <Modal
      open={modalData.isOpen}
      onClose={() => {
        setModalData({
          isOpen: false,
          images: [],
        });
      }}
      style={{
        zIndex: 99999999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: "#FFF",
          width: "90%",
          height: "fit-content",
          maxWidth: "400px",
          borderRadius: "10px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          position: "relative",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              background: "#FED4CC",
              padding: "16px",
              color: "#FF595C",
            }}
          >
            사진을 여러장 올리셨네요!
          </p>
          <p
            style={{
              marginTop: "10px",
              padding: "0 16px",
              fontSize: "16px",
            }}
          >
            이 사진들을 어떻게 처리할까요?
          </p>
        </div>
        <div
          style={{
            display: " flex",
            flexDirection: "column",
            gap: "10px",
            padding: "0 16px 16px",
          }}
        >
          <button
            style={{
              border: "1px solid #FEA69B",
              borderRadius: "8px",
              padding: "10px",
              fontSize: "16px",
              width: "100%",
              background: "#FEF3F1",
              textAlign: "start",
            }}
            onClick={async () => {
              const questionDataWithBookIdUploadImagePath =
                await handleQuestionDataWithDefaultBookUploadImages();
              await askImageQuestion({
                variables: {
                  input: {
                    bookId: questionDataWithBookIdUploadImagePath.bookId,
                    teacherId: questionDataWithBookIdUploadImagePath.teacherId,
                    studentId: questionDataWithBookIdUploadImagePath.studentId,
                    academyId: questionDataWithBookIdUploadImagePath.academyId,
                    images: questionDataWithBookIdUploadImagePath.images,
                    channel: channel as FcmChannelType,
                  },
                },
              });
              resetModalData();
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              하나의 질문으로 등록할게요
            </p>
            <p
              style={{
                fontSize: "12px",
                color: "#666666",
              }}
            >
              여러 사진을 모두 하나의 질문으로 묶어서 처리합니다.
            </p>
          </button>
          <button
            style={{
              border: "1px solid #FEA69B",
              borderRadius: "8px",
              padding: "10px",
              fontSize: "16px",
              width: "100%",
              background: "#FEF3F1",
              textAlign: "start",
            }}
            onClick={async () => {
              const questionDataWithBookIdUploadImagePath =
                await handleQuestionDataWithDefaultBookUploadImages();
              await Promise.allSettled(
                questionDataWithBookIdUploadImagePath.images.map(
                  async (imagesPath) =>
                    await askImageQuestion({
                      variables: {
                        input: {
                          bookId: questionDataWithBookIdUploadImagePath.bookId,
                          teacherId: questionDataWithBookIdUploadImagePath.teacherId,
                          studentId: questionDataWithBookIdUploadImagePath.studentId,
                          academyId: questionDataWithBookIdUploadImagePath.academyId,
                          images: imagesPath,
                          channel: channel as FcmChannelType,
                        },
                      },
                    })
                )
              );
              resetModalData();
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              여러 개의 질문으로 등록할게요.
            </p>
            <p
              style={{
                fontSize: "12px",
                color: "#666666",
              }}
            >
              각 사진을 서로 다른 질문으로 처리합니다.
            </p>
          </button>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flex: 1,
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0,0,0,0.8)",
              color: "#FFF",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
            <p>문제 등록 중입니다. 잠시만 기다려주세요.</p>
            <p>등록 문제가 많으면 시간이 걸릴 수 있습니다.</p>
          </div>
        )}
      </div>
    </Modal>
  );
}
