export type StyleKeyType = {
  mainContainer: React.CSSProperties;
  subContainer: React.CSSProperties;
};

type ButtonSectionStylesType = {
  portrait: StyleKeyType;
  unPortrait: StyleKeyType;
};

export const BUTTON_SECTION_STYLES: ButtonSectionStylesType = {
  portrait: {
    mainContainer: {
      position: "absolute",
      bottom: 0,
      height: "70px",
      backgroundColor: "white",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "92%",
    },
  },
  unPortrait: {
    mainContainer: {
      height: "100vh",
      backgroundColor: "white",
      width: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
    },
    subContainer: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      alignItems: "center",
      width: "92%",
      height: "100%",
    },
  },
} as const;

export type StyleKey = keyof StyleKeyType;
